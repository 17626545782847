// This full screen modal is designed for mobile

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StarIcon from '@mui/icons-material/Star';
import CustomModal from '@artemis/components/Modal';
import NavBar from '@artemis/components/NavBar';
import NavLogos from '@artemis/components/NavBar/NavLogos';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { PROMO_VALUES } from '@artemis/utils/query/constants';
import { rtColors } from '@ritualco/ritual-frontend-components';
import useBranchLink from '@artemis/utils/useBranchLink';
import {
  logMobileModalButtonClick,
  logMobileModalImpression,
  EVENT_ACTION_DOWNLOAD_APP_MODAL_CONTINUE_IN_APP_CLICK,
  EVENT_ACTION_DOWNLOAD_APP_MODAL_CONTINUE_IN_BROWSER_CLICK,
} from './analytics';

const Modal = styled(CustomModal)`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.background.card};
  overflow-y: auto;
`;

const LogoContainer = styled.div`
  margin: auto;
`;

const HeroContainer = styled.div`
  position: relative;
`;
const HeroBanner = styled(ResponsiveImage)`
  display: flex;
`;

const RitualAppLogo = styled(ResponsiveImage)`
  display: flex;
  position: absolute;
  width: 72px;
  left: 50%;
  transform: translate(-50%, -65%);
`;

const RatingsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledStarIcon = styled(StarIcon).attrs({
  sx: { color: rtColors.yellow500 },
})``;

const RatingsLabel = styled.span`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.rtColors.black700};
`;

const Content = styled.div`
  padding: 24px;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const Description = styled.p`
  text-align: center;
  margin: auto;
  margin-top: 16px;
  max-width: 315px;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  button:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: ${props => props.theme.rtColors.teal500};
  color: ${props => props.theme.palette.common.white};
  ${props => props.theme.typography.button};
  font-weight: ${props => props.theme.typography.fontWeightMedium};

  padding: 12px 24px;
  border-radius: 4pt;
  border: none;
  cursor: pointer;
  transition: 0.25s background-color ease;

  ${props =>
    props.$isGrayscale &&
    `
    background-color: ${props.theme.rtColors.black100};
    color: ${props.theme.rtColors.black1000};
  `}

  &:hover {
    background-color: ${props =>
      props.theme.palette.hover.onLightBg(
        props.$isGrayscale
          ? props.theme.rtColors.black100
          : props.theme.palette.primary,
      )};
  }
`;

const DownloadAppModal = ({ isOpen, onClose, merchantId }) => {
  const branchUrl = useBranchLink({ promoCode: PROMO_VALUES.GET_RITUAL });

  const onContinueInApp = () => {
    logMobileModalButtonClick(
      EVENT_ACTION_DOWNLOAD_APP_MODAL_CONTINUE_IN_APP_CLICK,
    );
    window.location.assign(branchUrl);
    onClose();
  };
  const onContinueInBrowser = () => {
    logMobileModalButtonClick(
      EVENT_ACTION_DOWNLOAD_APP_MODAL_CONTINUE_IN_BROWSER_CLICK,
    );
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      logMobileModalImpression(merchantId);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <NavBar>
        <LogoContainer>
          <NavLogos logos={['promo.ritualLogo.img']} />
        </LogoContainer>
      </NavBar>

      <HeroContainer>
        <HeroBanner id="downloadApp.banner.img" />
        <RitualAppLogo id="downloadApp.ritualLogo.img" />
      </HeroContainer>

      <RatingsContainer>
        <div>
          <StyledStarIcon />
          <StyledStarIcon />
          <StyledStarIcon />
          <StyledStarIcon />
          <StyledStarIcon />
        </div>
        <RatingsLabel>
          <FormattedMessage entry="downloadApp.ratingsLabel" />
        </RatingsLabel>
      </RatingsContainer>

      <Content>
        <Title>
          <FormattedMessage entry="downloadApp.modal.title" />
        </Title>
        <Description>
          <FormattedMessage entry="downloadApp.modal.description" />
        </Description>
        <ButtonContainer>
          <StyledButton onClick={onContinueInApp}>
            <FormattedMessage entry="downloadApp.modal.primaryButton" />
          </StyledButton>
          <StyledButton $isGrayscale onClick={onContinueInBrowser}>
            <FormattedMessage entry="downloadApp.modal.secondaryButton" />
          </StyledButton>
        </ButtonContainer>
      </Content>
    </Modal>
  );
};

DownloadAppModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  merchantId: PropTypes.string,
};

export default DownloadAppModal;
